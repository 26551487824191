/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add your custom styles below */
body {
  font-family: 'Exo 2', sans-serif;
}
#tsparticles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.holographic-text {
  background: linear-gradient(90deg, #ff00cc, #333399);
  background-size: 200% 200%;
  animation: holographic 5s ease infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes holographic {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.glow-button {
  background-color: #9b59b6;
  color: #fff;
  padding: 0.75rem 2rem;
  border-radius: 9999px;
  box-shadow: 0 0 10px rgba(155, 89, 182, 0.5);
  transition: box-shadow 0.3s;
}

.glow-button:hover {
  box-shadow: 0 0 20px rgba(155, 89, 182, 1);
}